import { coreApi } from "@/lib/backend";
import { OrderByDirection, type PaginatedNotificationViewModel } from "@/lib/backend/core.api";
import { infiniteQueryOptions, queryOptions } from "@/lib/query/utils";

const queries = (queryKey: Readonly<[string, number]>) => {
	const baseKey = [...queryKey, "notification"] as const;

	return {
		"~base": baseKey,

		all: (
			query: Parameters<typeof coreApi.members.memberNotificationControllerGetNotifications>[1] = {
				orderBy: { sentAt: OrderByDirection.Desc },
			},
		) =>
			queryOptions({
				queryKey: [...baseKey, "all", query] as const,
				queryFn: (context) =>
					coreApi.members
						.memberNotificationControllerGetNotifications(
							context.queryKey[1],
							context.queryKey[4],
							{
								signal: context.signal,
							},
						)
						.then((response) => response.data),
				placeholderData: {
					data: [],
					meta: { hasNextPage: false, hasPreviousPage: false, totalCount: 0 },
				} as Awaited<
					ReturnType<typeof coreApi.members.memberNotificationControllerGetNotifications>
				>["data"],
				select: (data) => ({
					...data,
					data: data.data.sort(
						(a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime(),
					),
				}),
			}),
		preferences: () =>
			queryOptions({
				queryKey: [...baseKey, "preferences"] as const,
				queryFn: (context) =>
					coreApi.members
						.memberNotificationPreferencesControllerGetNotificationPreferences(
							context.queryKey[1],
							{ signal: context.signal },
						)
						.then((response) => response.data),
			}),
		scheduled: (
			query: Parameters<
				typeof coreApi.members.memberNotificationControllerGetScheduledNotifications
			>[1] = {
				orderBy: { sentAt: OrderByDirection.Desc },
			},
		) =>
			queryOptions({
				queryKey: [...baseKey, "scheduled", query] as const,
				queryFn: (context) =>
					coreApi.members
						.memberNotificationControllerGetScheduledNotifications(
							context.queryKey[1],
							context.queryKey[4],
							{ signal: context.signal },
						)
						.then((response) => response.data),
				placeholderData: {
					data: [],
					meta: { hasNextPage: false, hasPreviousPage: false, totalCount: 0 },
				} as Awaited<
					ReturnType<typeof coreApi.members.memberNotificationControllerGetScheduledNotifications>
				>["data"],
				select: (data) => ({
					...data,
					data: data.data.sort(
						(a, b) => new Date(a.scheduledOn).getTime() - new Date(b.scheduledOn).getTime(),
					),
				}),
			}),

		infinite: {
			all: (
				query: Omit<
					Parameters<typeof coreApi.members.memberNotificationControllerGetNotifications>[1],
					"offset"
				> = {
					orderBy: { sentAt: OrderByDirection.Desc },
				},
			) =>
				infiniteQueryOptions({
					queryKey: [...baseKey, "infinite", "all", query] as const,
					queryFn: (context) =>
						coreApi.members
							.memberNotificationControllerGetNotifications(
								context.queryKey[1],
								{
									...context.queryKey[5],
									take: context.queryKey[5].take ?? 10,
									offset: context.pageParam * (context.queryKey[5].take ?? 10),
								},
								{
									signal: context.signal,
								},
							)
							.then((response) => response.data),
					// initialPageParam: 0,
					// placeholderData: { pageParams: [], pages: [] },
					getPreviousPageParam: (
						firstPage: PaginatedNotificationViewModel,
						allPages: PaginatedNotificationViewModel[],
					) => (firstPage.meta.hasPreviousPage ? allPages.length - 2 : undefined),
					getNextPageParam: (lastPage, allPages) =>
						lastPage.meta.hasNextPage ? allPages.length : undefined,
				}),
		},
	};
};

export { queries as memberNotificationQueries };
