import { coreApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";
import { memberNotificationQueries as notificationQueries } from "./notification";
import { memberPersonalTrainingQueries as personalTrainingQueries } from "./personal-training";
import { memberReservationQueries as reservationQueries } from "./reservation";
import { memberWaitingListQueries as waitingListQueries } from "./waiting-list";

const queries = (memberId: number) => {
	const baseKey = ["member", memberId] as const;

	return {
		"~base": baseKey,

		notification: notificationQueries(baseKey),
		personalTraining: personalTrainingQueries(baseKey),
		reservation: reservationQueries(baseKey),
		waitingList: waitingListQueries(baseKey),
		penalty: () =>
			queryOptions({
				queryKey: [...baseKey, "penalty"] as const,
				queryFn: (context) =>
					coreApi.members
						.memberPenaltyControllerGetCurrentPenalty(context.queryKey[1], {
							signal: context.signal,
						})
						.then((response) => response.data),
				staleTime: 0,
			}),
	};
};

export { queries as memberQueries };
