import type { InfiniteData } from "@tanstack/vue-query";
import { endOfDay, startOfDay } from "date-fns";
import { clubApi, reserveerApi } from "@/lib/backend";
import type {
	IomodelsCoachLedenOverviewExtern,
	IomodelsCoachMemberExtern,
} from "@/lib/backend/club.api";
import { infiniteQueryOptions, queryOptions } from "@/lib/query/utils";

const queries = {
	"~base": ["overview"] as const,

	liveFeed: () =>
		queryOptions({
			queryKey: [...queries["~base"], "live-feed"] as const,
			queryFn: (context) =>
				clubApi.liveFeed
					.liveFeedVoorCoachApp({ signal: context.signal })
					.then((response) => response.data),
			placeholderData: [] as Awaited<
				ReturnType<typeof clubApi.liveFeed.liveFeedVoorCoachApp>
			>["data"],
		}),
	appointments: (
		query: Parameters<typeof reserveerApi.fitnessafspraken.afsprakenVoorRange>[0] = {
			startDatum: startOfDay(Date.now()).toISOString(),
			eindDatum: endOfDay(Date.now()).toISOString(),
		},
	) =>
		queryOptions({
			queryKey: [...queries["~base"], "appointments", query] as const,
			queryFn: (context) =>
				reserveerApi.fitnessafspraken
					.afsprakenVoorRange(context.queryKey[2], { signal: context.signal })
					.then((response) => response.data),
			placeholderData: [] as Awaited<
				ReturnType<typeof reserveerApi.fitnessafspraken.afsprakenVoorRange>
			>["data"],
		}),

	infinite: {
		members: (query: Parameters<typeof clubApi.leden.overviewVoorCoachApp>[0]) =>
			infiniteQueryOptions({
				queryKey: [...queries["~base"], "infinite", "members", query] as const,
				queryFn: (context) =>
					clubApi.leden
						.overviewVoorCoachApp(
							{
								...context.queryKey[3],
								page: context.pageParam || context.queryKey[3].page,
							},
							{ signal: context.signal },
						)
						.then((response) => response.data),
				getPreviousPageParam: (firstPage: IomodelsCoachLedenOverviewExtern) =>
					firstPage.page <= 1 ? undefined : firstPage.page - 1,
				getNextPageParam: (lastPage) => (lastPage.nextPage ? lastPage.page + 1 : undefined),
			}),
	},
} as const;

export { queries as overviewQueries };

export function flattenMembers(
	param: InfiniteData<IomodelsCoachLedenOverviewExtern> | undefined,
): IomodelsCoachMemberExtern[] {
	if (!param || !param.pages) {
		return [];
	}

	return param.pages
		.flatMap((page) => page.users)
		.filter((user, index, self) => index === self.findIndex((value) => value.id === user.id));
}
