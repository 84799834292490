import { reserveerApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";

const queries = {
	"~base": ["gym"] as const,

	all: () =>
		queryOptions({
			queryKey: [...queries["~base"], "all"] as const,
			queryFn: (context) =>
				reserveerApi.vestigingen
					.vestigingen({
						signal: context.signal,
					})
					.then((response) => response.data),
			placeholderData: [] as Awaited<
				ReturnType<typeof reserveerApi.vestigingen.vestigingen>
			>["data"],
			select: (data) => data.sort((a, b) => a.naam.localeCompare(b.naam)),
			cacheTime: Infinity,
			staleTime: Infinity,
		}),
} as const;

export { queries as gymQueries };
