import { coachApi, coreApi } from "@/lib/backend";
import type { AanwezigheidExtern } from "@/lib/backend/coach.api";
import { queryOptions } from "@/lib/query/utils";

const queries = {
	"~base": ["grouplesson"] as const,

	all: (query?: Parameters<typeof coreApi.groupLessons.groupLessonControllerGetGroupLessons>[0]) =>
		queryOptions({
			queryKey: [...queries["~base"], "all", query] as const,
			queryFn: (context) =>
				coreApi.groupLessons
					.groupLessonControllerGetGroupLessons(context.queryKey[2], {
						signal: context.signal,
					})
					.then((response) => response.data),
			placeholderData: {
				data: [],
				meta: { hasNextPage: false, hasPreviousPage: false, totalCount: 0 },
			} as Awaited<
				ReturnType<typeof coreApi.groupLessons.groupLessonControllerGetGroupLessons>
			>["data"],
			select: (data) => ({ ...data, data: data.data.sort((a, b) => a.name.localeCompare(b.name)) }),
		}),

	single: (grouplessonId: number) => {
		const key = [...queries["~base"], "single", grouplessonId] as const;

		return {
			"~base": key,

			detail: () =>
				queryOptions({
					queryKey: [...key, "detail"] as const,
					queryFn: (context) =>
						coreApi.groupLessons
							.groupLessonControllerFindById(context.queryKey[2], {
								signal: context.signal,
							})
							.then((response) => response.data),
				}),
			attendance: () =>
				queryOptions({
					queryKey: [...key, "attendance"] as const,
					queryFn: async (
						context,
					): Promise<{
						lijst: (AanwezigheidExtern & {
							herhalend: boolean;
						})[];
						ingediend: boolean;
					}> => {
						const generalAttendance = await coachApi.api.lessenAanwezigheidslijstDetail(
							context.queryKey[2],
						);

						if (generalAttendance.status !== 200) {
							throw generalAttendance;
						}

						const repeatingAttendance =
							await coreApi.lessons.lessonAttendanceControllerGetAttendance(context.queryKey[2]);

						if (repeatingAttendance.status !== 200) {
							throw repeatingAttendance;
						}

						return {
							lijst: generalAttendance.data.lijst.map((member) => ({
								...member,
								herhalend:
									repeatingAttendance.data.data.find((a) => a.member.id === member.lidId)
										?.hasRecurringReservation || false,
							})),
							// @ts-expect-error - ingediend exists in the API response but is missing from type definition
							ingediend: generalAttendance.data.ingediend,
						};
					},
				}),
		};
	},
} as const;

export { queries as grouplessonQueries };
