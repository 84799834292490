
















































































































































































































































































































































































































































































































































































import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { DateTime } from "klokwerk";
import { mapActions as mapPiniaActions, mapStores } from "pinia";
import { clubApi } from "@/lib/backend";
import type {
	IomodelsCoachappGewrichtsAandoening,
	IomodelsCoachappIntake,
	IomodelsCoachappPersoonlijkeGegevens,
	IomodelsCoachappSpierBlessures,
} from "@/lib/backend/club.api";
import type { IomodelsLid } from "@/lib/backend/reserveer.api";
import { DEFAULT_LANGUAGE } from "@/lib/constants/misc";
import { GESLACHT_MAP, LANGUAGE_MAP } from "@/lib/constants/options";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { usePopupStore } from "@/pinia/popup";
import { CoachService } from "@/services/coach";
import { capitalize } from "@/utils/capitalize";
import { getDateFromDatum } from "@/utils/date";

type Data = {
	loading: boolean;
	imageShowing?: string;
	profileImage?: Blob;
	GESLACHT_MAP: typeof GESLACHT_MAP;
	LANGUAGE_MAP: typeof LANGUAGE_MAP;
};

export default defineComponent({
	name: "PfgFormIntake",
	props: {
		geslacht: {
			type: String as PropType<Geslacht>,
			required: false,
			default: undefined,
		},
		geboortedatum: {
			type: String,
			required: false,
			default: undefined,
		},
		voornaam: {
			type: String,
			required: false,
			default: undefined,
		},
		achternaam: {
			type: String,
			required: false,
			default: undefined,
		},
		email: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonNummer: {
			type: String,
			required: false,
			default: undefined,
		},
		telefoonMobiel: {
			type: String,
			required: false,
			default: undefined,
		},
		adres: {
			type: String,
			required: false,
			default: undefined,
		},
		postcode: {
			type: String,
			required: false,
			default: undefined,
		},
		plaats: {
			type: String,
			required: false,
			default: undefined,
		},
		coachingsGroep: {
			type: String as PropType<IomodelsLid["coachingsGroep"]>,
			required: false,
			default: undefined,
		},
		coachLid: {
			type: Number,
			required: false,
			default: undefined,
		},
		doelFrequentie: {
			type: Number,
			required: false,
			default: undefined,
		},
		medischCheck: {
			type: Boolean,
			required: false,
			default: false,
		},
		hartklachten: {
			type: Boolean,
			required: false,
			default: false,
		},
		kortademigheid: {
			type: Boolean,
			required: false,
			default: false,
		},
		longklachten: {
			type: String,
			required: false,
			default: null,
		},
		spierBlessures: {
			type: Object as PropType<IomodelsCoachappSpierBlessures>,
			required: false,
			default: (): IomodelsCoachappSpierBlessures => ({
				nek: false,
				schouder: false,
				arm: false,
				rug: false,
				bovenbeen: false,
				onderbeen: false,
				description: "",
			}),
		},
		gewrichtsAandoening: {
			type: Object as PropType<IomodelsCoachappGewrichtsAandoening>,
			required: false,
			default: (): IomodelsCoachappGewrichtsAandoening => ({
				nek: false,
				schouder: false,
				pols: false,
				rug: false,
				heup: false,
				knie: false,
				enkel: false,
				voet: false,
				description: "",
			}),
		},
		overigeKlachten: {
			type: String,
			required: false,
			default: undefined,
		},
		uitgezonderdeOefeningen: {
			type: String,
			required: false,
			default: undefined,
		},
		consultBijFysiotherapeut: {
			type: Boolean,
			required: false,
			default: false,
		},
		interesses: {
			type: Array as PropType<IomodelsCoachappIntake["interesses"]>,
			required: false,
			default: () => [],
		},
		diensten: {
			type: Array as PropType<IomodelsCoachappIntake["diensten"]>,
			required: false,
			default: () => [],
		},
		language: {
			type: String as PropType<IomodelsCoachappPersoonlijkeGegevens["language"]>,
			required: false,
			default: DEFAULT_LANGUAGE,
		},
	},
	setup() {
		const { id: currentGymId } = useCurrentGym();

		const { data: coaches } = useQuery({
			...queries.coach.all({ gymId: currentGymId.value }),
			queryKey: computed(() => queries.coach.all({ gymId: currentGymId.value }).queryKey),
		});

		return { getCoachName, coaches };
	},
	data(): Data {
		return {
			loading: this.$props.loading ?? false,
			imageShowing: undefined,
			profileImage: undefined,
			GESLACHT_MAP,
			LANGUAGE_MAP,
		};
	},
	computed: {
		...mapStores(usePopupStore),
		spierBlessuresKeys(): Omit<IomodelsCoachappSpierBlessures, "description"> {
			const { description, ...rest } = this.spierBlessures;

			return rest;
		},
		hasSpierBlessures(): boolean {
			return Object.values(this.spierBlessuresKeys).some((value) => value === true);
		},
		gewrichtsAandoeningKeys(): Omit<IomodelsCoachappGewrichtsAandoening, "description"> {
			const { description, ...rest } = this.gewrichtsAandoening;

			return rest;
		},
		hasGewrichtsAandoening(): boolean {
			return Object.values(this.gewrichtsAandoeningKeys).some((value) => value === true);
		},
		hasMedicalProblems(): boolean {
			return (
				this.hartklachten ||
				this.kortademigheid ||
				this.longklachten !== null ||
				this.hasSpierBlessures ||
				this.hasGewrichtsAandoening ||
				this.overigeKlachten !== null ||
				this.uitgezonderdeOefeningen !== null ||
				this.consultBijFysiotherapeut
			);
		},
	},
	methods: {
		capitalize,
		...mapPiniaActions(useGebruikerStore, ["updateTracking"]),
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));

			this.errors.clear();
		},
		handleFormToggle(
			name: "overigeKlachten" | "longklachten" | "uitgezonderdeOefeningen",
			value: boolean,
		) {
			if (value === true) {
				// @ts-expect-error Should not mutate
				this[name] = "";
			} else {
				// @ts-expect-error Should not mutate
				this[name] = null;
			}
		},
		async saveForm() {
			const isValid = await this.$validator.validateAll();

			if (!isValid) {
				return;
			}

			if (!this.medischCheck) {
				this.popupStore.open({
					title: "Let op!",
					body: "Je gaat nu opslaan zonder medische check, weet je het zeker?",
					buttons: {
						cancel: "Annuleren",
						confirm: "Ja",
					},
					callback: async () => await this.save(),
				});
			} else {
				await this.save();
			}
		},
		async save() {
			this.loading = true;

			try {
				const coachService = new CoachService();

				const response = await clubApi.intake.postIntake({
					hartklachten: this.$props.hartklachten,
					kortademigheid: this.$props.kortademigheid,
					longklachten: this.$props.longklachten,
					spierBlessures: this.hasSpierBlessures ? this.$props.spierBlessures : undefined,
					gewrichtsAandoening: this.hasGewrichtsAandoening
						? this.$props.gewrichtsAandoening
						: undefined,
					overigeKlachten: this.$props.overigeKlachten,
					uitgezonderdeOefeningen: this.$props.uitgezonderdeOefeningen,
					consultBijFysiotherapeut: this.$props.consultBijFysiotherapeut,
					diensten: this.$props.diensten,
					interesses: this.$props.interesses,
					medischCheck: this.$props.medischCheck,
					doelFrequentie: parseInt(String(this.$props.doelFrequentie)),
					personalInfo: {
						geslacht: this.$props.geslacht,
						voornaam: this.$props.voornaam,
						achternaam: this.$props.achternaam,
						email: this.$props.email,
						telefoon: this.$props.telefoonNummer,
						telefoonMobiel: this.$props.telefoonMobiel,
						adres: this.$props.adres,
						plaats: this.$props.plaats,
						geboortedatum: new DateTime(getDateFromDatum(this.$props.geboortedatum))
							.setHours(12)
							.setMinutes(0)
							.setSeconds(0)
							.setMilliseconds(0)
							.toISOString(),
						postcode: this.$props.postcode.replace(/\s/g, ""),
						language: this.$props.language,
					},
				});

				switch (response.status) {
					case 200: {
						const lidId = response.data.tijdelijkId;

						if (!lidId) {
							this.popupStore.open({
								title: "Gelukt!",
								body: "De intake is succesvol opgeslagen",
								buttons: {
									confirm: "Naar leden",
								},
								callback: () =>
									this.$router.push({
										name: "Leden",
									}),
							});

							this.reset();

							return true;
						}

						if (this.$props.coachingsGroep) {
							await clubApi.lid.groepVoorLidWijzigen(
								lidId,
								encodeURIComponent(this.$props.coachingsGroep),
							);
						}
						if (this.$props.coachLid) {
							await clubApi.lid.coachVoorLidWijzigen(lidId, this.$props.coachLid);
						}
						if (this.profileImage) {
							await coachService.updateLidProfileImage(lidId, this.profileImage);
						}

						await Promise.all(
							new Array(this.diensten).map((dienst) =>
								coachService.updateTracking(lidId, {
									type: dienst,
									value: true,
								}),
							),
						);

						this.popupStore.open({
							title: "Gelukt!",
							body: "De intake is succesvol opgeslagen",
							buttons: {
								confirm: "Naar nieuw lid",
							},
							callback: () =>
								this.$router.push({
									name: "Gebruiker",
									params: {
										id: String(lidId),
									},
								}),
						});

						this.reset();

						return true;
					}

					default:
						throw response;
				}
			} catch (error: { message: string } | { statusText: string } | string | unknown) {
				const message =
					(error as { message: string })?.message ||
					(error as { statusText: string })?.statusText ||
					error;

				this.popupStore.showError(
					`Er ging iets mis bij het opslaan van de intake: ${message}.<br/>Probeer het later nog eens.`,
				);

				return false;
			} finally {
				this.loading = false;
			}
		},
	},
});
