import { coachQueries as coach } from "./coach";
import { grouplessonQueries as grouplesson } from "./grouplesson";
import { gymQueries as gym } from "./gym";
import { lessonQueries as lesson } from "./lesson";
import { memberQueries as member } from "./member";
import { overviewQueries as overview } from "./overview";
import { trainerQueries as trainer } from "./trainer";

export const queries = {
	coach,
	grouplesson,
	gym,
	lesson,
	member,
	overview,
	trainer,
};
