import { reserveerApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";

const queries = (queryKey: Readonly<[string, number]>) => {
	const baseKey = [...queryKey, "personal-training"] as const;

	return {
		"~base": baseKey,

		all: () =>
			queryOptions({
				queryKey: [...baseKey, "all"] as const,
				queryFn: (context) =>
					reserveerApi.persoonlijkeTrainingen
						.trainingsProgrammas(context.queryKey[1], {
							signal: context.signal,
						})
						.then((response) => response.data),
				placeholderData: [] as Awaited<
					ReturnType<typeof reserveerApi.persoonlijkeTrainingen.trainingsProgrammas>
				>["data"],
				select: (data) =>
					data.sort((a, b) => new Date(b.startDatum).getTime() - new Date(a.startDatum).getTime()),
			}),

		single: (programId: number) => {
			const key = [...baseKey, "single", programId] as const;

			return {
				"~base": key,

				overview: () =>
					queryOptions({
						queryKey: [...key, "overview"] as const,
						queryFn: (context) =>
							reserveerApi.persoonlijkeTrainingen
								.trainingsProgrammaOverzicht(context.queryKey[4], {
									signal: context.signal,
								})
								.then((response) => response.data),
					}),
				currentDay: () =>
					queryOptions({
						queryKey: [...key, "current-day"] as const,
						queryFn: (context) =>
							reserveerApi.persoonlijkeTrainingen
								.getHuidigeTrainingsDag(context.queryKey[1], context.queryKey[4], {
									signal: context.signal,
								})
								.then((response) => response.data),
					}),
				week: (week: number) => ({
					day: (day: number) =>
						queryOptions({
							queryKey: [...key, "week", week, "day", day] as const,
							queryFn: (context) =>
								reserveerApi.persoonlijkeTrainingen
									.getTrainingsDag(
										context.queryKey[1],
										context.queryKey[4],
										context.queryKey[6],
										context.queryKey[8],
										{
											signal: context.signal,
										},
									)
									.then((response) => response.data),
						}),
				}),
			};
		},
	};
};

export { queries as memberPersonalTrainingQueries };
