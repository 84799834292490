



























































































































































































import { type PropType, computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { mapActions } from "vuex";
import { coachApi } from "@/lib/backend";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { LOCATION_DEFAULT, useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { flattenMembers } from "@/lib/query/queries/overview";
import { toast } from "@/lib/vue2-sonner";
import { useAppStore } from "@/pinia/app";
import { getDateFromDatum, getDayMonthYear } from "@/utils/date";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				search?: boolean;
				les: LesUitgebreid;
				memberId?: number;
			}>,
			default: () => ({ search: false, memberId: undefined }),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	setup(props) {
		const selectedMemberId = ref<number | undefined>(props.data.memberId);
		const message = ref<string | undefined>(undefined);
		const recurring = ref(false);
		const hasError = ref(false);
		const { data: gyms } = useQuery(queries.gym.all());

		const appStore = useAppStore();

		const { gym: currentGym } = useCurrentGym();
		const filter_search = ref<string>("");
		const filter_gym = ref<number | undefined>(currentGym.value?.id);

		const filterValue_gym = computed(
			() =>
				gyms.value?.find((gym) => gym.id === filter_gym.value)?.slug ??
				currentGym.value?.slug ??
				LOCATION_DEFAULT.slug,
		);

		watch(filter_gym, () => (selectedMemberId.value = undefined));
		watch(filter_search, () => (selectedMemberId.value = undefined));

		const { status, data } = useInfiniteQuery({
			...queries.overview.infinite.members({
				vestiging: filter_gym.value ? filterValue_gym.value : "",
				search: filter_search.value,
				sort: "check-in",
			}),
			queryKey: computed(
				() =>
					queries.overview.infinite.members({
						vestiging: filter_gym.value ? filterValue_gym.value : "",
						search: filter_search.value,
						sort: "check-in",
					}).queryKey,
			),
		});

		const members = computed(() => flattenMembers(data.value));

		return {
			selectedMemberId,
			message,
			recurring,
			hasError,
			gyms,
			appStore,
			filter_search,
			filter_gym,
			status,
			members,
		};
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		async aanmelden() {
			if (!this.selectedMemberId || !(await this.$validator.validateAll())) {
				toast.error("Niet mogelijk om lid aan te melden");
				return;
			}

			await toast
				.promise(
					coachApi.api.lessenAanwezigheidslijstCreate({
						lesId: this.data.les.les_id,
						lijst: [
							{
								lidId: this.selectedMemberId,
								herhalend: this.recurring,
								status: "aangemeld",
							},
						],
					}),
					{
						loading: "Lid aanmelden...",
						success: () => {
							this.callback();
							this.message = "Lid succesvol aangemeld";
							return this.message;
						},
						error: (error: unknown) => {
							this.hasError = true;
							this.message = formatErrorMessage(error);
							return this.message;
						},
					},
				)
				?.unwrap();
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: false,
					},
				},
				callback: async (closeModal: () => void, user: { id: number }) => {
					closeModal();
					this.openModal({
						name: "groepsles-aanmelden",
						data: {
							...this.data,
							memberId: user.id,
						},
						callback: this.callback
							? this.callback
							: () => {
									return;
								},
					});
				},
			});
		},
		getDateFromDatum,
		getDayMonthYear,
	},
});
