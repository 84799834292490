import { reserveerApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";

const queries = (queryKey: Readonly<[string, number]>) => {
	const baseKey = [...queryKey, "reservation"] as const;

	return {
		"~base": baseKey,

		future: () =>
			queryOptions({
				queryKey: [...baseKey, "future"] as const,
				queryFn: (context) =>
					reserveerApi.reserveringen
						.toekomstigeReserveringen(context.queryKey[1], {
							signal: context.signal,
						})
						.then((response) => response.data),
				placeholderData: [] as Awaited<
					ReturnType<typeof reserveerApi.reserveringen.toekomstigeReserveringen>
				>["data"],
				select: (data) =>
					data.sort((a, b) => new Date(a.datum).getTime() - new Date(b.datum).getTime()),
			}),
		past: (query: Parameters<typeof reserveerApi.reserveringen.historischeReserveringen>[1]) =>
			queryOptions({
				queryKey: [...baseKey, "past", query] as const,
				queryFn: (context) =>
					reserveerApi.reserveringen
						.historischeReserveringen(context.queryKey[1], context.queryKey[4], {
							signal: context.signal,
						})
						.then((response) => response.data),
				placeholderData: [] as Awaited<
					ReturnType<typeof reserveerApi.reserveringen.historischeReserveringen>
				>["data"],
				select: (data) =>
					data.sort((a, b) => new Date(b.datum).getTime() - new Date(a.datum).getTime()),
			}),
		recurring: () =>
			queryOptions({
				queryKey: [...baseKey, "recurring"] as const,
				queryFn: (context) =>
					reserveerApi.reserveringen
						.herhalendeReserveringen(context.queryKey[1], {
							signal: context.signal,
						})
						.then((response) => response.data),
				placeholderData: [] as Awaited<
					ReturnType<typeof reserveerApi.reserveringen.herhalendeReserveringen>
				>["data"],
			}),
	};
};

export { queries as memberReservationQueries };
