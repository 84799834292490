



























































































import { type PropType, computed, defineComponent, ref } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { clubApi } from "@/lib/backend";
import type { CoachViewModel } from "@/lib/backend/core.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { getCoachName } from "@/lib/getCoachName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { toast } from "@/lib/vue2-sonner";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				memberIds: number[];
				gymId?: number;
				callback?: VoidFunction;
			}>,
			required: true,
		},
	},
	emits: {
		close() {
			return true;
		},
	},
	setup(props, { emit }) {
		const isOpen = ref(false);
		const selectedCoach = ref<CoachViewModel | null>(null);

		const { id: currentGymId } = useCurrentGym();
		const gymId = computed(() => props.data.gymId ?? currentGymId.value);

		const { data: coaches } = useQuery({
			...queries.coach.all({ gymId: gymId.value }),
			queryKey: computed(() => queries.coach.all({ gymId: gymId.value }).queryKey),
			enabled: isOpen,
		});

		async function submit() {
			if (!selectedCoach.value) {
				throw new Error("No coach selected");
			}

			await toast
				.promise(
					clubApi.coach.ledenToewijzen(selectedCoach.value.id, { ledenIds: props.data.memberIds }),
					{
						loading: props.data.memberIds.length === 1 ? "Lid toewijzen..." : "Leden toewijzen...",
						success: () => {
							props.data.callback?.();
							emit("close");
							return props.data.memberIds.length === 1 ? "Lid toegewezen" : "Leden toegewezen";
						},
						error: formatErrorMessage,
					},
				)
				?.unwrap();
		}

		return { getCoachName, isOpen, selectedCoach, coaches, submit };
	},
});
