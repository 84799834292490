import { reserveerApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";

const queries = {
	"~base": ["trainer"] as const,

	grouplessons: (trainerId: Parameters<typeof reserveerApi.trainers.groepslessenVoorTrainer>[0]) =>
		queryOptions({
			queryKey: [...queries["~base"], trainerId, "grouplessons"] as const,
			queryFn: (context) =>
				reserveerApi.trainers
					.groepslessenVoorTrainer(context.queryKey[1], {
						signal: context.signal,
					})
					.then((response) => response.data),
			placeholderData: [] as Awaited<
				ReturnType<typeof reserveerApi.trainers.groepslessenVoorTrainer>
			>["data"],
			select: (data) => data.sort((a, b) => a.naam.localeCompare(b.naam)),
		}),
} as const;

export { queries as trainerQueries };
