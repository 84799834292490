import { startOfMinute } from "date-fns";
import { coreApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";

const queries = (queryKey: Readonly<[string, number]>) => {
	const baseKey = [...queryKey, "waiting-list"] as const;

	return {
		"~base": baseKey,

		all: (query?: Parameters<typeof coreApi.members.lessonWaitingListControllerGetByMember>[1]) =>
			queryOptions({
				queryKey: [
					...baseKey,
					{
						...query,
						where: { lessonGte: startOfMinute(new Date()).toISOString(), ...query?.where },
					},
				] as const,
				queryFn: (context) =>
					coreApi.members
						.lessonWaitingListControllerGetByMember(context.queryKey[1], context.queryKey[3], {
							signal: context.signal,
						})
						.then((response) => response.data),
				placeholderData: {
					data: [],
					meta: { hasNextPage: false, hasPreviousPage: false, totalCount: 0 },
				} as Awaited<
					ReturnType<typeof coreApi.members.lessonWaitingListControllerGetByMember>
				>["data"],
				select: (data) => ({
					...data,
					data: data.data.sort((a, b) =>
						!b.signedUpAt
							? 1
							: !a.signedUpAt
								? -1
								: new Date(b.signedUpAt).getTime() - new Date(a.signedUpAt).getTime(),
					),
				}),
			}),
	};
};

export { queries as memberWaitingListQueries };
