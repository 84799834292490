import { coreApi } from "@/lib/backend";
import { getCoachName } from "@/lib/getCoachName";
import { queryOptions } from "@/lib/query/utils";

const queries = {
	"~base": ["coach"] as const,

	all: (query?: Parameters<typeof coreApi.coaches.coachControllerFindAll>[0]) =>
		queryOptions({
			queryKey: [...queries["~base"], "all", query] as const,
			queryFn: (context) =>
				coreApi.coaches
					.coachControllerFindAll(context.queryKey[2], { signal: context.signal })
					.then((response) => response.data),
			placeholderData: {
				data: [],
				meta: { hasNextPage: false, hasPreviousPage: false, totalCount: 0 },
			} as Awaited<ReturnType<typeof coreApi.coaches.coachControllerFindAll>>["data"],
			select: (data) => ({
				...data,
				data: data.data.sort((a, b) => getCoachName(a).localeCompare(getCoachName(b))),
			}),
			cacheTime: Infinity,
			staleTime: Infinity,
		}),
} as const;

export { queries as coachQueries };
