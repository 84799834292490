








































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { useInfiniteQuery, useQuery } from "@tanstack/vue-query";
import { mapActions } from "vuex";
import { LOCATION_DEFAULT, useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { queries } from "@/lib/query/queries";
import { flattenMembers } from "@/lib/query/queries/overview";

export default defineComponent({
	setup() {
		const selectedMemberId = ref<number | undefined>(undefined);
		const { data: gyms } = useQuery(queries.gym.all());

		const { gym: currentGym } = useCurrentGym();
		const filter_search = ref<string>("");
		const filter_gym = ref<number | undefined>(currentGym.value?.id);

		const filterValue_gym = computed(
			() =>
				gyms.value?.find((gym) => gym.id === filter_gym.value)?.slug ??
				currentGym.value?.slug ??
				LOCATION_DEFAULT.slug,
		);

		watch(filter_gym, () => (selectedMemberId.value = undefined));
		watch(filter_search, () => (selectedMemberId.value = undefined));

		const { status, data } = useInfiniteQuery({
			...queries.overview.infinite.members({
				vestiging: filter_gym.value ? filterValue_gym.value : "",
				search: filter_search.value,
				sort: "check-in",
			}),
			queryKey: computed(
				() =>
					queries.overview.infinite.members({
						vestiging: filter_gym.value ? filterValue_gym.value : "",
						search: filter_search.value,
						sort: "check-in",
					}).queryKey,
			),
		});

		const members = computed(() => flattenMembers(data.value));

		return { selectedMemberId, filter_search, filter_gym, gyms, status, members };
	},
	methods: {
		...mapActions("modal", { openModal: "openModal" }),
		naarLid() {
			this.$emit("close");
			this.$router.push({
				name: "Member appointment create",
				params: { id: String(this.selectedMemberId) },
			});
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: true,
						title: "Het lid is succesvol aangemaakt",
						body: "Maak eventueel een afspraak met het lid",
						cancelText: "Sluiten",
						actionText: "Maak afspraak",
					},
				},
				callback: ({ id }: { id: number }) => {
					this.$router.push({
						name: "Member appointment create",
						params: { id: String(id) },
					});
				},
			});
		},
	},
});
