import type {
	InfiniteQueryObserverOptions,
	QueryKey,
	QueryObserverOptions,
	QueryOptions,
} from "@tanstack/query-core";

export function queryOptions<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
): QueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey> & {
	queryKey: TQueryKey;
};

export function queryOptions<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: QueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): QueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
	queryKey: TQueryKey;
};

export function queryOptions(options: unknown) {
	return options;
}

export function infiniteQueryOptions<
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey,
>(
	options: InfiniteQueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
): InfiniteQueryObserverOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey> & {
	queryKey: TQueryKey;
};

export function infiniteQueryOptions(options: unknown) {
	return options;
}
