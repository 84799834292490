var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('p',[_vm._v(" Aan wie wil je "+_vm._s(_vm.$props.data.memberIds.length)+" "+_vm._s(_vm.$props.data.memberIds.length > 1 ? "leden" : "lid")+" toewijzen? ")]),_c('div',{staticClass:"select"},[_c('div',{staticClass:"select_option_selected",attrs:{"focusable":"true","tabindex":"0"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.selectedCoach ? _vm.getCoachName(_vm.selectedCoach) : "Geen coach")+" "),_c('svg',{class:{ reverse: _vm.isOpen },attrs:{"width":"10","height":"8","viewBox":"0 0 10 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.848 6.6432L9.04375 1.53C9.46003 0.86395 8.98119 -3.9633e-07 8.19575 -3.61669e-07L1.80425 -7.96194e-08C1.01881 -4.4959e-08 0.539969 0.86395 0.956249 1.53L4.152 6.6432C4.54367 7.26987 5.45633 7.26987 5.848 6.6432Z","fill":"black"}})])]),(_vm.isOpen)?_c('div',{staticClass:"select_option-list"},_vm._l((_vm.coaches ? _vm.coaches.data : []),function(coach){return _c('div',{key:coach.id,staticClass:"select_option-list_option",on:{"click":function () {
						_vm.selectedCoach = coach;
						_vm.isOpen = false;
					}}},[_c('div',{staticClass:"grid flex-1 justify-between"},[_c('div',{staticClass:"user"},[_c('img',{staticClass:"pf",attrs:{"src":coach.imageUrl
									? coach.imageUrl
									: ("https://ui-avatars.com/api/?name=" + (_vm.getCoachName(coach))),"width":"35","height":"35"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.getCoachName(coach)))])]),_c('div',{staticClass:"flex items-center justify-end gap-2"},[_c('span',{staticClass:"text-sm opacity-75"},[_vm._v("30% sporters:")]),_c('span',{staticClass:"min-w-5 text-left tabular-nums"},[_vm._v(" "+_vm._s(coach.membersCount.coachingGroup30)+" ")])])])])}),0):_vm._e()]),_c('div',{class:{
			invisible: _vm.isOpen,
			actions: true,
		}},[_c('button',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"button-txt"},[_vm._v("Sluiten")])]),_c('button',{staticClass:"button v_brand_secondary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10",attrs:{"disabled":_vm.selectedCoach === null},on:{"click":_vm.submit}},[_c('span',{staticClass:"button-txt"},[_vm._v("Toewijzen")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"pfg-header"},[_c('div',{staticClass:"pfg-header_heading"},[_c('h1',{staticClass:"s_elem-has-icon-left"},[_vm._v("Lid toewijzen")])])])}]

export { render, staticRenderFns }