import { computed, watch } from "@vue/composition-api";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { performForAllApiInstances } from "@/lib/backend";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { queries } from "@/lib/query/queries";
import { logger } from "@/logger";

export const LOCATION_DEFAULT = {
	id: 23,
	slug: "ommen",
} as const;

export function useCurrentGym() {
	const queryClient = useQueryClient();
	const { data: gyms } = useQuery(queries.gym.all());

	/** @deprecated Legacy, please use `gymId` as source of truth */
	const locationSlug = useStorage<string>(LS_LOCATION, LOCATION_DEFAULT.slug, localStorage, {
		serializer: StorageSerializers.string,
		onError: logger.error,
	});

	const locationId = computed(() => gyms.value?.find((gym) => gym.slug == locationSlug.value)?.id);

	const gymId = useStorage<number>(
		"gymId",
		locationId.value ?? gyms.value?.[0]?.id ?? LOCATION_DEFAULT.id,
		localStorage,
		{
			serializer: StorageSerializers.number,
			onError: logger.error,
		},
	);

	const gym = computed(() => gyms.value?.find((gym) => gym.id === gymId.value));
	const gymSlug = computed(() => gym.value?.slug);

	watch(
		[gyms, gym],
		([currentGyms, currentGym]) => {
			if (!currentGyms || currentGyms.length === 0 || currentGym) {
				return;
			}

			gymId.value = currentGyms[0].id;
		},
		{ immediate: true },
	);

	watch(
		gymSlug,
		(currentGymSlug) => {
			if (!currentGymSlug) {
				return;
			}

			locationSlug.value = currentGymSlug;

			performForAllApiInstances(
				(apiInstance) => (apiInstance.defaults.headers.common["Gym-Location"] = currentGymSlug),
			);

			queryClient.refetchQueries(queries.overview.liveFeed());
		},
		{ immediate: true },
	);

	return {
		/** @deprecated Please use dynamic `slug` instead */
		legacy_locationSlug: locationSlug,
		id: gymId,
		slug: gymSlug,
		gym,
	};
}
