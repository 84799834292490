import { addDays, endOfDay, secondsToMilliseconds, startOfDay } from "date-fns";
import { coachApi, coreApi, reserveerApi } from "@/lib/backend";
import { queryOptions } from "@/lib/query/utils";
import { getDateFromDatumAndTijd } from "@/utils/date";

const queries = {
	"~base": ["lesson"] as const,

	all: (filterMetDatumRange?: Partial<Parameters<typeof reserveerApi.lessen.lessenVoorRange>[0]>) =>
		queryOptions({
			queryKey: [
				...queries["~base"],
				"all",
				{
					startDatum: startOfDay(new Date()).toISOString(),
					eindDatum: endOfDay(addDays(new Date(), 16)).toISOString(),
					...filterMetDatumRange,
				},
			] as const,
			queryFn: (context) =>
				reserveerApi.lessen
					.lessenVoorRange(context.queryKey[2], {
						signal: context.signal,
					})
					.then((response) => response.data),
			placeholderData: [] as Awaited<
				ReturnType<typeof reserveerApi.lessen.lessenVoorRange>
			>["data"],
			select: (data) =>
				data.sort((a, b) => new Date(a.datum).getTime() - new Date(b.datum).getTime()),
			staleTime: secondsToMilliseconds(10),
		}),
	current: () =>
		queryOptions({
			queryKey: [...queries["~base"], "current"] as const,
			queryFn: async (context) =>
				await coachApi.api
					.lessenActueelList({ signal: context.signal })
					.then((response) => response.data),
			placeholderData: [] as Awaited<ReturnType<typeof coachApi.api.lessenActueelList>>["data"],
			select: (data) =>
				data.sort((a, b) => {
					const dateA = getDateFromDatumAndTijd(a.datum, a.tijd);
					const dateB = getDateFromDatumAndTijd(b.datum, b.tijd);

					if (dateA > dateB) return 1;
					else if (dateA < dateB) return -1;
					else return 0;
				}),
		}),

	single: (lessonId: number) => {
		const key = [...queries["~base"], "single", lessonId] as const;

		return {
			"~base": key,

			waitingList: () =>
				queryOptions({
					queryKey: [...key, "waiting-list"] as const,
					queryFn: async (context) =>
						await coreApi.members
							.lessonWaitingListControllerGetByLesson(context.queryKey[2], {
								signal: context.signal,
							})
							.then((response) => response.data),
					placeholderData: {
						data: [],
						meta: { totalCount: 0, hasPreviousPage: false, hasNextPage: false },
					} as Awaited<
						ReturnType<typeof coreApi.members.lessonWaitingListControllerGetByLesson>
					>["data"],
					select: (data) => ({
						...data,
						data: data.data.sort((a, b) =>
							!b.signedUpAt
								? 1
								: !a.signedUpAt
									? -1
									: new Date(a.signedUpAt).getTime() - new Date(b.signedUpAt).getTime(),
						),
					}),
				}),
		};
	},
} as const;

export { queries as lessonQueries };
