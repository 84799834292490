import { parse } from "date-fns";

export const rule_dateFormatNL = {
	getMessage: () => "Vul een geboortedatum in met het format: 18-7-2017",
	validate(value: string) {
		const formats = ["d-M-y", "dd-MM-y"];

		for (const format of formats) {
			try {
				const date = parse(value, format, new Date());
				if (!isNaN(date.getTime())) {
					return true;
				}
			} catch {
				continue;
			}
		}

		return false;
	},
};
