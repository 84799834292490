import type { IomodelsCoachappIntake } from "@/lib/backend/club.api";
import type {
	AanwezigheidFitnessAfspraakExtern,
	DatumRange,
	FitnessAfspraakExtern,
	GroepslessenResultaat,
	GuessedReservering,
} from "@/lib/backend/coach.api";
import type {
	IomodelsAanwezigheidFitnessAfspraakExtern,
	IomodelsFitnessAfspraakExtern,
	IomodelsVestiging,
} from "@/lib/backend/reserveer.api";
import { config } from "@/lib/config";
import type { Doel, MedicalData, MetingenData, SubscriptionData } from "@/services/types/coach";
import { BaseService } from "./base";

export class CoachService extends BaseService {
	// https://coach.profitgym.info/api/swagger/index.html?url=/api/api-docs/swagger.yaml#/
	#baseUrl = config.coach.server;

	private url(path: string): string {
		return `${this.#baseUrl}${path}`;
	}

	async updateLidProfileImage<ResponseData = string>(lidId: number, profileImage: Blob) {
		const data = new FormData();
		data.append("file", profileImage);

		return await this.post<ResponseData, FormData>(this.url(`/user/${lidId}/profile-image`), {
			body: data,
			headers: {
				Accept: "application/json",
				"Accept-Language": "en-US,en;q=0.8",
				"Content-Type": "multipart/form-data;",
			},
		});
	}

	async updateTracking<
		ResponseData = unknown,
		RequestBody = {
			value: boolean;
			type: IomodelsCoachappIntake["diensten"][number];
		},
	>(lidId: number, body: RequestBody) {
		return await this.post<ResponseData, RequestBody>(this.url(`/user/${lidId}/change-tracking`), {
			body,
		});
	}

	async getLidGroepslessen<ResponseData = GroepslessenResultaat, RequestBody = void>(
		lidId: number,
	) {
		return await this.get<ResponseData, RequestBody>(this.url(`/user/${lidId}/groepslessen`));
	}

	async getLidReserveringen<ResponseData = GuessedReservering[], RequestBody = void>(
		lidId: number,
	) {
		return await this.get<ResponseData, RequestBody>(this.url(`/user/${lidId}/reserveringen`));
	}

	async getLidAppointmentsBooked<ResponseData = FitnessAfspraakExtern[], RequestBody = DatumRange>(
		lidId: number,
		datumRange: RequestBody,
	) {
		return await this.post<ResponseData, RequestBody>(
			this.url(`/appointments-booked/member/${lidId}`),
			{ body: datumRange },
		);
	}

	async makeAppointment<
		ResponseData = IomodelsFitnessAfspraakExtern,
		RequestBody = AanwezigheidFitnessAfspraakExtern,
	>(afspraakId: number, afspraak: RequestBody) {
		return await this.post<ResponseData, RequestBody>(
			this.url(`/fitnessafspraken/${afspraakId}/aanwezigheid`),
			{ body: afspraak },
		);
	}
	async updateAppointment<
		ResponseData = IomodelsFitnessAfspraakExtern,
		RequestBody = IomodelsAanwezigheidFitnessAfspraakExtern,
	>(afspraakId: number, afspraak: RequestBody) {
		return await this.post<ResponseData, RequestBody>(
			this.url(`/fitnessafspraken/${afspraakId}/aanwezigheid`),
			{ body: afspraak },
		);
	}

	async getVestigingen<ResponseData = IomodelsVestiging[], RequestBody = void>() {
		return await this.get<ResponseData, RequestBody>(this.url("/vestigingen"));
	}

	async getLidDoel<ResponseData = Doel, RequestBody = void>(lidId: number) {
		return await this.get<ResponseData, RequestBody>(this.url(`/user/${lidId}/goal`));
	}

	async saveLidDoel<ResponseData = Doel, RequestBody = Doel>(lidId: number, doel: RequestBody) {
		return await this.post<ResponseData, RequestBody>(this.url(`/user/${lidId}/goal`), {
			body: doel,
		});
	}

	async getMetingen<ResponseData = MetingenData, RequestBody = void>(lidId: number) {
		return await this.get<ResponseData, RequestBody>(this.url(`/user/${lidId}/metingen`));
	}

	async deleteMeting<ResponseData = { data: "deleted" }>(lidId: number, metingId: number) {
		return await this.delete<ResponseData, { metingId: number }>(
			this.url(`/user/${lidId}/meting`),
			{ body: { metingId } },
		);
	}

	async getPersonalMedical<ResponseData = MedicalData, RequestBody = void>(lidId: number) {
		return await this.get<ResponseData, RequestBody>(this.url(`/user/${lidId}/personal-medical`));
	}

	async savePersonalMedical<ResponseData = { data: "saved" }, RequestBody = MedicalData>(
		lidId: number,
		body: RequestBody,
	) {
		return await this.post<ResponseData, RequestBody>(this.url(`/user/${lidId}/personal-medical`), {
			body,
		});
	}

	async getPersonalSubscription<ResponseData = SubscriptionData, RequestBody = void>(
		lidId: number,
	) {
		return await this.get<ResponseData, RequestBody>(
			this.url(`/user/${lidId}/personal-subscription`),
		);
	}
}
